import $ from 'legacy/jquery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Config: any = {};

Config.Chosen = {
  Default: {
    search_contains: true,
    disable_search_threshold: 10,
  },
  DisableSearch: {
    search_contains: true,
    disable_search_threshold: 1000,
  },
  AllowDeselect: {
    search_contains: true,
    disable_search_threshold: 10,
    allow_single_deselect: true,
  },
};

Config.Select2 = {
  Default: {
    minimumResultsForSearch: 10,
    width: 'resolve',
  },
};

Config.Modal = {
  Standard: {
    draggable: true,
    resizable: false,
    modal: true,
    minHeight: 0,
    dialogClass: 'modal-dialog',
    open: function () {
      $('body').css({ overflow: 'hidden' });
      $('.ui-widget-overlay').bind('click', function (this: JQuery) {
        $(this)
          .siblings('.ui-dialog')
          .find('.ui-dialog-titlebar-close')
          .click();
      });
    },
    beforeClose: function () {
      $('body').css({ overflow: 'inherit' });
    },
  },
  custom: function (overrides: unknown) {
    return $.extend({}, Config.Modal.Standard, overrides);
  },
};

Config.Recurly = Config.Recurly || {};

Config.Sortable = {
  Standard: {
    items: 'li:not(.not-sortable)',
    placeholder: 'sortable-placeholder',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    start: function (e: any, ui: any) {
      // subtract 6 to account for dashed border on placeholder
      ui.placeholder.height(ui.item.height() - 6);
    },
    tolerance: 'pointer',
    revert: 200,
  },
};

Config.FullCalendar = {
  Default: {
    height: 550,
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay',
    },
    timezone: 'local',
    scrollTime: '09:00:00',
    defaultView: 'agendaWeek',
    editable: true,
    selectable: true,
    selectHelper: true,
  },
};

Config.TimeZone = {
  timeFormat: function (format: string) {
    function timeFormatMap(clock12Hour: string, clock24Hour: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const TimeFormat = (window as any).TimeFormat;
      if (typeof TimeFormat === 'undefined') {
        return clock12Hour;
      } else {
        switch (TimeFormat) {
          case 'clock_24_hr': // TODO: change to customer facing value, app/presenters/time_format_presenter.rb
            return clock24Hour;
          default:
            return clock12Hour;
        }
      }
    }

    switch (format) {
      case 'time':
        return timeFormatMap('h:mma', 'H:mm');
      case 'time_double_digits':
        return timeFormatMap('hh:mma', 'HH:mm');
      case 'time_hide_minutes_when_0':
        return timeFormatMap('h(:mm)a', 'H:mm');
      case 'timezone':
        return timeFormatMap('Z', 'Z');
    }
  },
  dateFormat: function (format: string) {
    function dateFormatMap(
      monthDayYear: string,
      dayMonthYear: string,
      yearMonthDay: string
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const DateFormat = (window as any).DateFormat;
      if (typeof DateFormat === 'undefined') {
        return monthDayYear;
      } else {
        switch (DateFormat) {
          case 'DD/MM/YYYY':
            return dayMonthYear;
          case 'YYYY/MM/DD':
            return yearMonthDay;
          default:
            return monthDayYear;
        }
      }
    }

    switch (format) {
      case 'date':
        return dateFormatMap('MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD');
      case 'date_pretty':
        return dateFormatMap('MMM D, YYYY', 'D MMM YYYY', 'YYYY MMM D');
      case 'date_pretty_long_month':
        return dateFormatMap('MMMM D, YYYY', 'D MMMM YYYY', 'YYYY MMMM D');
      case 'date_pretty_long':
        return dateFormatMap(
          'dddd, MMMM D, YYYY',
          'dddd, D MMMM YYYY',
          'dddd, YYYY MMMM D'
        );
      case 'date_pretty_without_year':
        return dateFormatMap('MMM D', 'D MMM', 'MMM D');
      case 'datepicker':
        return dateFormatMap('mm/dd/yy', 'dd/mm/yy', 'yy/mm/dd');
      case 'trend_chart':
        return dateFormatMap('%m/%d', '%d/%m', '%m/%d');
      case 'date_without_year_with_day_of_week':
        return dateFormatMap('ddd M/D', 'ddd D/M', 'ddd M/D');
      case 'date_without_year_with_day_of_week_2':
        return dateFormatMap('ddd MMM Do', 'ddd D MMM', 'ddd D MMM');
      case 'birch_date_picker':
        return dateFormatMap('MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd');
    }
  },
};

export default Config;
